import request from '@/utils/request'
// import store from '@/store'
import Vuetify from '@/plugins/vuetify'
var langId = Vuetify.preset.lang.current

export function newCompanylist () {
  return request({
    url: langId + '/dashboard/newcompanylist',
    method: 'get',
    params: {
      per_page: 5,
      search_column: 'created_time',
      search_text: '2019-01-03',
      search_operator: '>='
    }
  })
}

export function expiredCompanylist () {
  return request({
    url: langId + '/dashboard/expirecompanylist',
    method: 'get',
    params: {
      per_page: 5
      // search_column: 'created_time',
      // search_text: '2019-01-03',
      // search_operator: '>='
    }
  })
}

export function countRevenue () {
  return request({
    url: langId + '/invoice/countrenevue',
    method: 'get'
  })
}

export function countBussiness () {
  return request({
    url: langId + '/business/joined/count',
    method: 'get'
  })
}

export function countBussinessPublished () {
  return request({
    url: langId + '/business/published/count',
    method: 'get'
  })
}

export function countMarketplace () {
  return request({
    url: langId + '/marketplace/joined/count',
    method: 'get'
  })
}

export function countAppointment () {
  return request({
    url: langId + '/appointment/count',
    method: 'get'
  })
}

export function platformDemograph (params) {
  return request({
    url: langId + '/platform/demograph',
    method: 'get',
    params: params
  })
}

export function treatementTypeDemograph (params) {
  return request({
    url: langId + '/treatmenttype/count',
    method: 'get',
    params: params
  })
}

export function subcriptionOverview (params) {
  return request({
    url: langId + '/subscriptiongraph',
    method: 'get',
    params: params
  })
}

export function appointmentOverview (params) {
  return request({
    url: langId + '/overview/appointment',
    method: 'get',
    params: params
  })
}
