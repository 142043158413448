<template>
  <v-container class="grey lighten-5">
    <v-row class="mb-6">
      <v-col :md="3">
        <v-skeleton-loader
          v-if="loadingfetchCountRevenue"
          type="article, actions"
        ></v-skeleton-loader>
        <v-card
          v-if="!loadingfetchCountRevenue"
          :raised="true" :elevation="10" class="rounded-xl">
          <v-img src="@/assets/back_green.svg" class="p-16">
            <span style="color: #767676">Revenue This Month</span>
            <div class="d-flex flex-wrap mb-6">
              <div class="font-weight-medium" style="font-size: 30px; font-size: 1.5vw; color: #4CD137">Rp. {{ dataCountRevenue.invoice.ftotal_month }}</div>
              <div class="pt-1">
                <span class="ml-2" style="font-size: 14px; font-size: 0.8vw; background: #4CD137; color: #FFFFFF">+{{ dataCountRevenue.invoice.fincrease }}</span>
              </div>
            </div>
            <div class="d-flex flex-column">
              <span class="caption" style="color: #BEC2C4">Total Revenue</span>
              <span style="color: #4CD137">Rp {{ dataCountRevenue.invoice.ftotal_all }}</span>
            </div>
          </v-img>
        </v-card>
      </v-col>
      <v-col :md="6">
        <v-skeleton-loader
          v-if="loadingfetchCountBusiness"
          type="article, actions"
        ></v-skeleton-loader>
        <v-card v-if="!loadingfetchCountBusiness" :raised="true" :elevation="10" class="rounded-xl" >
          <v-img src="@/assets/back_purple.svg" class="p-16">
            <div class="d-flex flex-wrap justify-space-between">
              <div>
                <div>
                  <span style="color: #767676">Business Joined</span>
                  <span class="caption ml-2 font-weight-light" style="color: #BEC2C4">This Month</span>
                  <div class="d-flex flex-wrap mb-6">
                    <div class="headline font-weight-medium">{{ dataCountRevenue.bussiness.total_month }}</div>
                    <div class="pt-1">
                      <span class="caption ml-2" style="background: #4CD137; color: #FFFFFF">+{{ dataCountRevenue.bussiness.fincrease }}</span>
                    </div>
                  </div>
                  <div class="d-flex flex-column">
                    <span class="caption" style="color: #BEC2C4">Total Business</span>
                    <span>{{ dataCountRevenue.bussiness.total_all }}</span>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <span style="color: #767676">Marketplace User Joined</span>
                  <span class="caption ml-2 font-weight-light" style="color: #BEC2C4">This Month</span>
                  <div class="d-flex flex-wrap mb-6">
                    <div class="headline font-weight-medium">{{ dataCountRevenue.marketplace.total_month }}</div>
                    <div class="pt-1">
                      <span class="caption ml-2" style="background: #4CD137; color: #FFFFFF">+{{ dataCountRevenue.marketplace.fincrease }}</span>
                    </div>
                  </div>
                  <div class="d-flex flex-column">
                    <span class="caption" style="color: #BEC2C4">Total User</span>
                    <span>{{ dataCountRevenue.marketplace.total_all }}</span>
                  </div>
                </div>
              </div>
            </div>
          </v-img>
        </v-card>
      </v-col>
      <v-col :md="3">
        <v-skeleton-loader
          v-if="loadingfetchCountAppointment"
          type="article, actions"
        ></v-skeleton-loader>
        <v-card v-if="!loadingfetchCountAppointment" :raised="true" :elevation="10" class="rounded-xl" >
          <v-img src="@/assets/back_pink.svg" class="p-16">
            <span style="color: #767676">Appointment</span>
            <span class="caption ml-2 font-weight-light" style="color: #BEC2C4">This Month</span>
            <div class="d-flex flex-wrap mb-6">
              <div class="headline font-weight-medium" >{{ dataCountRevenue.appointment.total_month }}</div>
            </div>
            <div class="d-flex flex-column">
              <span class="caption" style="color: #BEC2C4">Total Appointment</span>
              <span>{{ dataCountRevenue.appointment.total_all }}</span>
            </div>
          </v-img>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-6">
      <v-col :md="5">
        <v-card :loading="loadingfetchPlatformDemograph" :raised="true" :elevation="10" class="rounded-xl" >
          <v-img src="@/assets/back_green.svg" class="p-16">
            <div class="mb-10">
              <span style="color: #767676">Platform Demograph</span>
              <div class="float-right mr-6">
                <filter-date-range :filter="false" @filter="filterPlatform"></filter-date-range>
              </div>
            </div>
            <div class="d-flex flex-wrap">
              <ve-pie :data="dataPlatform" width="220px" height="250px" :legend-visible="false" :settings="ringSettings" :colors="colorsRing" />
              <div class="pt-6">
                <div class="ml-4">
                  <div v-for="(item, key) in dataPlatform.rows" :key="key">
                      <div class="d-flex flex-wrap mb-2">
                        <div class="rounded-lg mr-3" :style="'background:' + item.color + ';width: 20px; height: 40px'"></div>
                        <div class="d-flex flex-column mt-n1">
                          <span style="font-size: 14px">{{ item.name }} <span class="font-weight-bold">({{ item.total }})</span></span>
                          <span style="color: #4CD137">Rp. {{ item.frenevue }}</span>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </v-img>
        </v-card>
      </v-col>
      <v-col :md="7" class="pl-2">
        <v-card :loading="loadingfetchTreatmentDemograph" :raised="true" :elevation="10" class="rounded-xl" >
          <v-img src="@/assets/back_green_widht.svg" class="p-16" :aspect-ratio="1.9">
            <div class="mb-10">
              <span style="color: #767676">Treatment Type Demograph</span>
              <div class="float-right mr-6">
                <filter-date-range :filter="false" @filter="filterTreatment"></filter-date-range>
              </div>
            </div>
            <div class="d-flex flex-wrap">
              <ve-pie :data="dataTreatmentDemograph" width="220px" height="250px" :legend-visible="false" :settings="ringSettings" :colors="colorsTreatment" />
              <div class="d-flex flex-wrap">
                <div v-for="(column, idy) in columnsAdmin" :key="idy" class="mr-4" >
                  <div v-for="(item, itemKey) in column" :key="itemKey">
                      <div class="d-flex flex-wrap mb-4">
                        <div class="rounded-lg mr-3" :style="'background:' + item.color + ';width: 20px; height: 40px'"></div>
                        <div class="d-flex flex-column mt-n1">
                          <span style="font-size: 14px">{{ item.name }} <span class="font-weight-bold">({{ item.total }})</span></span>
                          <span style="color: #4CD137">Rp. {{ item.frenevue }}</span>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </v-img>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-6">
      <v-col :md="4">
        <div class="mb-3 pl-2 mt-6">
          <span class="font-weight-bold">Zenwel Biz Overview</span>
        </div>
        <v-card :loading="loadingfetchSubcriptionOverview" :raised="true" :elevation="10" class="rounded-xl">
          <div class="p-16">
            <div class="mb-10">
              <div>
                <span style="color: #767676; font-size: 14px" >Subscription</span>
                </div>
              <div class="my-3">
                <!-- <div class="mb-2" style="color: #BEC2C4; font-size: 14px" >This Week Revenue: <span class="font-weight-bold" style="color: #4CD137">Rp 500.000.000</span></div> -->

                <filter-date-range :filter="true" @filter="filterSubscription"></filter-date-range>
              </div>
              <div style="color: #BEC2C4; font-size: 14px" >Total Business: <span class="font-weight-bold" style="color: #000000">{{ dataSubscription.total_business }}</span></div>
            </div>
            <div class="d-flex flex-wrap">
              <ve-pie :data="dataSubscription.pieData" width="200px" height="230px" :legend-visible="false" :settings="ringSettings" :colors="colorsSubscription" />
              <div class="ml-4">
                  <div v-for="(item, itemKey) in dataSubscription.pieData.rows" :key="itemKey">
                      <div class="d-flex flex-wrap mb-4">
                        <div class="rounded-lg mr-3" :style="'background:' + item.color + ';width: 20px; height: 40px'"></div>
                        <div class="d-flex flex-column mt-n1">
                          <span style="font-size: 14px">{{ item.name }}</span>
                          <span class="font-weight-bold">{{ item.total }}</span>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            <div class="mb-10">
              <div class="ml-n7 pr-4">
                <ve-line :data="dataSubscription.chartData" :xAxis="xAxis" :yAxis="yAxis" :legend-visible="false" :colors="colorsLine" :settings="lineSettings" :extend="extend" height='220px' />
              </div>
              <div class="text-center">
                <span style="font-size: 14px" >Total Revenue</span>
                <div style="color: #767676;font-size: 14px">({{ dataSubscription.date_start }} - {{ dataSubscription.date_end}})</div>
                <div><span class="font-weight-bold" style="color: #4CD137; font-size: 20px">Rp {{ dataSubscription.ftotalrevenue }}</span></div>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col :md="4">
        <div class="mb-3 pl-2  mt-6">
          <span class="font-weight-bold">Widget Overview</span>
        </div>
        <v-card :loading="loadingfetchWidgetOverview" :raised="true" :elevation="10" class="rounded-xl" >
          <div class="p-16">
            <div class="mb-10">
              <div>
                <span style="color: #767676; font-size: 14px" >Appointment</span>
              </div>
              <div class="my-3">
                <filter-date-range :filter="true" @filter="filterWidget"></filter-date-range>
              </div>
              <div style="color: #BEC2C4; font-size: 14px" >Total Appointment: <span class="font-weight-bold" style="color: #000000">{{ dataWidget.total_appointment }}</span></div>
            </div>
            <div class="d-flex flex-wrap">
              <ve-pie :data="dataWidget.pieData" width="200px" height="230px" :legend-visible="false" :settings="ringSettings" :colors="colorsSubscription" />
              <div class="ml-4 mt-10">
                  <div v-for="(item, key) in dataWidget.pieData.rows" :key="key">
                      <div class="d-flex flex-wrap mb-4">
                        <div class="rounded-lg mr-3" :style="'background:' + item.color + ';width: 20px; height: 40px'"></div>
                        <div class="d-flex flex-column mt-n1">
                          <span style="font-size: 14px">{{ item.name }}</span>
                          <span class="font-weight-bold">{{ item.total }}</span>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            <div class="mb-10">
              <div class="ml-n7 pr-4">
                <ve-line :data="dataWidget.chartData" :xAxis="xAxis" :yAxis="yAxis" :legend-visible="false" :colors="colorsLine" :settings="lineSettings" :extend="extend" height='220px' />
              </div>
              <div class="text-center">
                <span style="font-size: 14px" >Total Revenue</span>
                <div style="color: #767676;font-size: 14px">({{ dataWidget.date_start }} - {{ dataWidget.date_end}})</div>
                <div><span class="font-weight-bold" style="color: #4CD137; font-size: 20px">Rp {{ dataWidget.ftotalrevenue }}</span></div>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col :md="4">
        <div class="mb-3 pl-2  mt-6">
          <span class="font-weight-bold">Marketplace Overview</span>
        </div>
        <v-card :loading="loadingfetchMarketplaceOverview" :raised="true" :elevation="10" class="rounded-xl" >
          <div class="p-16">
            <div class="mb-10">
              <div>
                <span style="color: #767676; font-size: 14px" >Appointment</span>
              </div>
              <div class="my-3">
                <filter-date-range :filter="true" @filter="filterMarketplace"></filter-date-range>
              </div>
              <div style="color: #BEC2C4; font-size: 14px" >Total Appointment: <span class="font-weight-bold" style="color: #000000">{{ dataMarketplace.total_appointment }}</span></div>
            </div>
            <div class="d-flex flex-wrap">
              <ve-pie :data="dataMarketplace.pieData" width="200px" height="230px" :legend-visible="false" :settings="ringSettings" :colors="colorsSubscription" />
              <div class="ml-4">
                  <div v-for="(item, key) in dataMarketplace.pieData.rows" :key="key">
                      <div class="d-flex flex-wrap mb-4">
                        <div class="rounded-lg mr-3" :style="'background:' + item.color + ';width: 20px; height: 40px'"></div>
                        <div class="d-flex flex-column mt-n1">
                          <span style="font-size: 14px">{{ item.name }}</span>
                          <span class="font-weight-bold">{{ item.total }}</span>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
            <div class="mb-10">
              <div class="ml-n7 pr-4">
                <ve-line :data="dataMarketplace.chartData" :xAxis="xAxis" :yAxis="yAxis" :legend-visible="false" :colors="colorsLine" :settings="lineSettings" :extend="extend" height='220px' />
              </div>
              <div class="text-center">
                <span style="font-size: 14px" >Total Revenue</span>
                <div style="color: #767676;font-size: 14px">({{ dataMarketplace.date_start }} - {{ dataMarketplace.date_end}})</div>
                <div><span class="font-weight-bold" style="color: #4CD137; font-size: 20px">Rp {{ dataMarketplace.ftotalrevenue }}</span></div>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment'
import echarts from 'echarts/lib/echarts'
import {
  countRevenue,
  countBussiness,
  countMarketplace,
  countAppointment,
  platformDemograph,
  treatementTypeDemograph,
  subcriptionOverview,
  appointmentOverview } from '@/api/dashboard'

export default {
  name: 'Dashboard',
  data () {
    return {
      lineSettings: {
        area: true,
        smooth: 0.6,
        labelMap: {
          sales: 'Sales',
          appointment: 'Appointments'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: '#00D79E'
          }, {
            offset: 1,
            color: '#BEF4E6'
          }])
        }
      },
      xAxis: {
        type: 'category',
        show: true,
        boundaryGap: false,
        splitLine: {
          show: false
        }
      },
      yAxis: {
        show: false,
        boundaryGap: false,
        splitLine: {
          show: false
        }
      },
      extend: {
        'xAxis.0.axisLabel.rotate': 15
      },
      colorsLine: ['#00D79E', '#1DA1F3'],
      date: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      date2: [new Date().toISOString().substr(0, 10), moment(new Date().toISOString().substr(0, 10), 'YYYY-MM-DD').add(6, 'd').format('YYYY-MM-DD')],
      menu1: false,
      menu2: false,
      ringSettings: {
        offsetY: 110,
        label: {
          normal: {
            show: true,
            position: 'inside'
          }
        }
      },
      colorsRing: ['#00D79E', '#00B082', '#004A37'],
      colorsTreatment: ['#83D9FF', '#7CFDBE', '#FF94CD', '#C7A8FF', '#B6C2FF', '#FFB677', '#FFD740', '#8CFFFF', '#FF845D'],
      colorsSubscription: ['#E0E0E0', '#46E8BD', '#2882F5', '#7E11A1'],
      dataCountRevenue: {
        invoice: {},
        bussiness: {},
        marketplace: {},
        appointment: {}
      },
      dataPlatform: {
        columns: ['fpercent', 'percent'],
        rows: []
      },
      dataTreatmentDemograph: {
        columns: ['fpercent', 'percent'],
        rows: []
      },
      dataSubscription: {
        total_business: 0,
        frevenue: '0',
        revenue: 0,
        pieData: {
          columns: ['fpercent', 'percent'],
          rows: []
        },
        chartData: {
          columns: ['flabel', 'value'],
          rows: []
        }
      },
      dataWidget: {
        total_appointment: 0,
        frevenue: '0',
        revenue: 0,
        pieData: {
          columns: ['fpercent', 'percent'],
          rows: []
        },
        chartData: {
          columns: ['flabel', 'value'],
          rows: []
        }
      },
      dataMarketplace: {
        total_appointment: 0,
        frevenue: '0',
        revenue: 0,
        pieData: {
          columns: ['fpercent', 'percent'],
          rows: []
        },
        chartData: {
          columns: ['flabel', 'value'],
          rows: []
        }
      },
      filterBtn: [
        {
          id: 1,
          value: '1 Week',
          string: '1week',
          color: '#E0E0E0',
          colorTxt: '#767676'
        },
        {
          id: 2,
          value: '1 Month',
          string: '1month',
          color: '#E0E0E0',
          colorTxt: '#767676'
        },
        {
          id: 3,
          value: '1 Year',
          string: '1year',
          color: '#E0E0E0',
          colorTxt: '#767676'
        },
        {
          id: 4,
          value: '3 Year',
          string: '3year',
          color: '#E0E0E0',
          colorTxt: '#767676'
        },
        {
          id: 5,
          value: '5 Year',
          string: '5year',
          color: '#E0E0E0',
          colorTxt: '#767676'
        }
      ],
      date_start: moment().subtract(6, 'd').format('YYYY-MM-DD'),
      date_end: moment().format('YYYY-MM-DD'),
      loadingfetchCountRevenue: false,
      loadingfetchCountBusiness: false,
      loadingfetchCountMarketplace: false,
      loadingfetchCountAppointment: false,
      loadingfetchPlatformDemograph: false,
      loadingfetchTreatmentDemograph: false,
      loadingfetchSubcriptionOverview: false,
      loadingfetchWidgetOverview: false,
      loadingfetchMarketplaceOverview: false
    }
  },

  mounted () {
    this.fetchCountRevenue()
    this.fetchCountBusiness()
    this.fetchCountMarketplace()
    this.fetchCountAppointment()
    this.fetchPlatformDemograph()
    this.fetchTreatmentDemograph()
    this.fetchSubcriptionOverview()
    this.fetchWidgetOverview()
    this.fetchMarketplaceOverview()
  },

  computed: {
    columnsAdmin () {
      let columns = []
      let mid = Math.ceil(this.dataTreatmentDemograph.rows.length / 2)
      for (let col = 0; col < 2; col++) {
        columns.push(this.dataTreatmentDemograph.rows.slice(col * mid, col * mid + mid))
      }
      return columns
    }
  },

  methods: {
    fetchCountRevenue () {
      this.loadingfetchCountRevenue = true
      countRevenue().then((response) => {
        this.dataCountRevenue.invoice = response.data.data
        this.loadingfetchCountRevenue = false
      }).catch(() => {
        this.loadingfetchCountRevenue = false
      })
    },

    fetchCountBusiness () {
      this.loadingfetchCountBusiness = true
      countBussiness().then((response) => {
        this.dataCountRevenue.bussiness = response.data.data
        this.loadingfetchCountBusiness = false
      }).catch(() => {
        this.loadingfetchCountBusiness = false
      })
    },

    fetchCountMarketplace () {
      this.loadingfetchCountMarketplace = true
      countMarketplace().then((response) => {
        this.dataCountRevenue.marketplace = response.data.data
        this.loadingfetchCountMarketplace = false
      }).catch(() => {
        this.loadingfetchCountMarketplace = false
      })
    },

    fetchCountAppointment () {
      this.loadingfetchCountAppointment = true
      countAppointment().then((response) => {
        this.dataCountRevenue.appointment = response.data.data
        this.loadingfetchCountAppointment = false
      }).catch(() => {
        this.loadingfetchCountAppointment = false
      })
    },

    fetchPlatformDemograph (filterDate) {
      this.loadingfetchPlatformDemograph = true
      let param = {
        date_start: this.date_start,
        date_end: this.date_end
      }

      if (typeof filterDate !== 'undefined') {
        if (filterDate.id !== 2) {
          param.date_start = filterDate.date_start
          param.date_end = filterDate.date_end
        } else {
          param = {}
        }
      }
      platformDemograph(param).then((response) => {
        this.dataPlatform.rows = response.data.data
        this.loadingfetchPlatformDemograph = false
      }).catch(() => {
        this.loadingfetchPlatformDemograph = false
      })
    },

    fetchTreatmentDemograph (filterDate) {
      this.loadingfetchTreatmentDemograph = true
      let param = {
        date_start: this.date_start,
        date_end: this.date_end
      }

      if (typeof filterDate !== 'undefined') {
        if (filterDate.id !== 2) {
          param.date_start = filterDate.date_start
          param.date_end = filterDate.date_end
        } else {
          param = {}
        }
      }
      treatementTypeDemograph(param).then((response) => {
        this.dataTreatmentDemograph.rows = response.data.data
        this.loadingfetchTreatmentDemograph = false
      }).catch(() => {
        this.loadingfetchTreatmentDemograph = false
      })
    },

    fetchSubcriptionOverview (filterDate) {
      this.loadingfetchSubcriptionOverview = true
      let param = {}
      if (typeof filterDate !== 'undefined') {
        param.date_start = filterDate.date_start
        param.date_end = filterDate.date_end
      } else {
        param.date_start = this.date_start
        param.date_end = this.date_end
      }
      subcriptionOverview(param).then((response) => {
        this.dataSubscription.total_business = response.data.data.total_business
        this.dataSubscription.ftotalrevenue = response.data.data.graph.ftotalrevenue
        this.dataSubscription.totalrevenue = response.data.data.graph.totalrevenue
        this.dataSubscription.pieData.rows = response.data.data.subscription
        this.dataSubscription.date_start = moment(response.data.data.graph.date_start).format('DD MMM YYYY')
        this.dataSubscription.date_end = moment(response.data.data.graph.date_end).format('DD MMM YYYY')
        this.dataSubscription.pieData.rows.map(i => {
          if (i.name === 'Free') {
            i.color = 'transparent linear-gradient(180deg, #E0E0E0 0%, #EEEEEE 100%) 0% 0% no-repeat padding-box;'
          }
          if (i.name === 'Basic') {
            i.color = 'transparent linear-gradient(180deg, #46E8BD 0%, #0BD0D4 100%) 0% 0% no-repeat padding-box;'
          }
          if (i.name === 'Pro') {
            i.color = 'transparent linear-gradient(0deg, #2882F5 0%, #0BD0D4 100%) 0% 0% no-repeat padding-box;'
          }
          if (i.name === 'Enterprise') {
            i.color = 'transparent linear-gradient(180deg, #7E11A1 0%, #BC4DAA 100%) 0% 0% no-repeat padding-box;'
          }
        })
        this.dataSubscription.chartData.rows = response.data.data.graph.datagraph
        this.loadingfetchSubcriptionOverview = false
      }).catch(() => {
        this.loadingfetchSubcriptionOverview = false
      })
    },

    fetchWidgetOverview (filterDate) {
      this.loadingfetchWidgetOverview = true
      let param = {
        date_start: this.date_start,
        date_end: this.date_end,
        platform: 'O'
      }

      if (typeof filterDate !== 'undefined') {
        param.date_start = filterDate.date_start
        param.date_end = filterDate.date_end
      }

      appointmentOverview(param).then((response) => {
        this.dataWidget.total_appointment = response.data.data.total_appoinment
        this.dataWidget.ftotalrevenue = response.data.data.graph.ftotalrevenue
        this.dataWidget.totalrevenue = response.data.data.graph.totalrevenue
        this.dataWidget.pieData.rows = response.data.data.group
        this.dataWidget.chartData.rows = response.data.data.graph.datagraph
        this.dataWidget.date_start = moment(response.data.data.graph.date_start).format('DD MMM YYYY')
        this.dataWidget.date_end = moment(response.data.data.graph.date_end).format('DD MMM YYYY')
        this.loadingfetchWidgetOverview = false
      }).catch(() => {
        this.loadingfetchWidgetOverview = false
      })
    },

    fetchMarketplaceOverview (filterDate) {
      this.loadingfetchMarketplaceOverview = true
      let param = {
        date_start: this.date_start,
        date_end: this.date_end,
        platform: 'M'
      }

      if (typeof filterDate !== 'undefined') {
        param.date_start = filterDate.date_start
        param.date_end = filterDate.date_end
      }
      appointmentOverview(param).then((response) => {
        this.dataMarketplace.total_appointment = response.data.data.total_appoinment
        this.dataMarketplace.ftotalrevenue = response.data.data.graph.ftotalrevenue
        this.dataMarketplace.totalrevenue = response.data.data.graph.totalrevenue
        this.dataMarketplace.pieData.rows = response.data.data.group
        this.dataMarketplace.chartData.rows = response.data.data.graph.datagraph
        this.dataMarketplace.date_start = moment(response.data.data.graph.date_start).format('DD MMM YYYY')
        this.dataMarketplace.date_end = moment(response.data.data.graph.date_end).format('DD MMM YYYY')
        this.loadingfetchMarketplaceOverview = false
      }).catch(() => {
        this.loadingfetchMarketplaceOverview = false
      })
    },

    filterSubscription (data) {
      this.fetchSubcriptionOverview(data)
    },

    filterWidget (data) {
      this.fetchWidgetOverview(data)
    },

    filterMarketplace (data) {
      this.fetchMarketplaceOverview(data)
    },

    filterTreatment (data) {
      this.fetchTreatmentDemograph(data)
    },

    filterPlatform (data) {
      this.fetchPlatformDemograph(data)
    }
  }
}
</script>

<style lang="scss">
.mytable .v-table tbody tr:not(:last-child) {
    border-bottom: none;
}
</style>
